/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired 
           
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

            
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



  $('.contentTabsMobile .ih-item a').on("click", function(e) {
    var $this = $(this),
        $id = $this.attr('id'),
        $class = '.' + $('.about-' + $id).attr('class').replace('hide', '');
    
    $('.default-text').addClass('hide');
    $('.about-' + $id).removeClass('hide');
    $('div[class*=about]').not($class).addClass('hide');
  });


// CAROUSEL ANIMATION

//Function to animate slider captions 
function doAnimations( elems ) {
  //Cache the animationend event in a variable
  var animEndEv = 'webkitAnimationEnd animationend';
  
  elems.each(function () {
    var $this = $(this),
      $animationType = $this.data('animation');
    $this.addClass($animationType).one(animEndEv, function () {
      $this.removeClass($animationType);
    });
  });
}



$('.entry-share-btns a').on('click', function(e) {
  e.preventDefault();
  var link = jQuery(this).attr('href');
  var width = 840;
  var height = 464;
  var popupName = 'popup_' + width + 'x' + height;
  var left = (screen.width-width) / 2;
  var top = 100;
  var params = 'width=' + width + ',height=' + height + ',location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,left=' + left + ',top=' + top;
  window[popupName] = window.open(link, popupName, params);
  if (window.focus) {
    window[popupName].focus();
  }
  return true;
});


// BACK TO TOP
$('.back_top').click(function(event) {
  event.preventDefault();
  var link = this;
  $.smoothScroll({
    scrollTarget: link.hash,
    offset: -110
  });
});



// COMPLEX TABS WITH HEADER IMAGES

  function extend( a, b ) {
    for( var key in b ) { 
      if( b.hasOwnProperty( key ) ) {
        a[key] = b[key];
      }
    }
    return a;
  }

  function CBPFWTabs( el, options ) {
    this.el = el;
    this.options = extend( {}, this.options );
      extend( this.options, options );
      this._init();
  }

  CBPFWTabs.prototype.options = {
    start : 0
  };

  CBPFWTabs.prototype._init = function() {
    // tabs elems
    this.tabs = [].slice.call( this.el.querySelectorAll( 'nav > ul > li' ) );
    // content items
    this.items = [].slice.call( this.el.querySelectorAll( '.content-wrap > section' ) );
    // current index
    this.current = -1;
    // show current content item
    this._show();
    // init events
    this._initEvents();
  };

  CBPFWTabs.prototype._initEvents = function() {
    var self = this;
    this.tabs.forEach( function( tab, idx ) {
      tab.addEventListener( 'click', function( ev ) {
        ev.preventDefault();
        self._show( idx );
      } );
    } );
  };

  CBPFWTabs.prototype._show = function( idx ) {
    if( this.current >= 0 ) {
      this.tabs[ this.current ].className = this.items[ this.current ].className = 'ih-item square colored effect6 bottom_to_top';
    }
    // change current
    this.current = idx !== undefined ? idx : this.options.start >= 0 && this.options.start < this.items.length ? this.options.start : 0;
    this.items[ this.current ].className = 'content-current';
  };

  // add to global namespace
  window.CBPFWTabs = CBPFWTabs;

  
  [].slice.call( document.querySelectorAll( '.tabs' ) ).forEach( function( el ) {
    new CBPFWTabs( el );
  });



})(jQuery); // Fully reference jQuery after this point.

jQuery(window).trigger('resize').trigger('scroll');

var isTouchOnly = true;
$(document).on('mousemove.bdh', function () {
  isTouchOnly = false;
  $(document).off('mousemove.bdh');
  console.debug('MouseMove detected, this device has a mouse');
});